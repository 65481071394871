<template>
  <el-dialog
    top="2em"
    width="70%"
    :visible.sync="dialogVisible"
    append-to-body
    class="primary-eq-eo-select-modal"
    :close-on-click-modal="false"
  >
      <div slot="title" class="pt-0 pb-0">
        Энергообъекты
      </div> 
      <energy-objects-select-filter @active-change="filterActive = $event" :filter-model="filterModel" />
      <energy-objects-select-table 
        :data="data" 
        :filter-active="filterActive" 
        :filter-model="filterModel"
        @on-update="selectedEnergyObject = $event" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectEnergyObjects">Применить</el-button>
        <el-button type="default" @click="cancelSelectEnergyObjects">Отменить</el-button>
      </div>
  </el-dialog>
</template>

<script>
import EnergyObjectsSelectTable from  './EnergyObjectsSelectTable';
import EnergyObjectsSelectFilter from './EnergyObjectsSelectFilter';

export default {
  name: 'EnergyObjectsModal',
  props: ['value', 'data'],
  components: { EnergyObjectsSelectTable, EnergyObjectsSelectFilter },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      selectedEnergyObject: {},
      filterModel: {
        nameFilter: '',
        eoIdFilter: '',
        isLocalNsiFilter: false,
      },
      filterActive: true,
    };
  },
  methods: {
    selectEnergyObjects() {      
      this.$emit('on-update', this.selectedEnergyObject);
      this.dialogVisible = false;
    },
    cancelSelectEnergyObjects() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.primary-eq-eo-select-modal {
  .el-dialog__header {
    padding-bottom: 0px;
  }
  .el-dialog__body {
    padding: 0px 20px;
    overflow-y: hidden;
  }
  
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
